import { PropTypes } from "prop-types";
import useNewsletter from "@/hooks/useNewsletter";
import { cn } from "@/utils/cn";
import { useTranslation } from "react-i18next";
import HTMLParser from "../HTMLParser";

const Newsletter = ({ listId, title, text }) => {
  const { message, onSubmit, email, setEmail, sentRequest } =
    useNewsletter(listId);

  const { t } = useTranslation();

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <div className="newsletter-container flex items-center justify-center bg-deep-green px-4 text-white">
      <div className="mx-auto max-w-[635px]">
        <div className="mb-7 lg:mb-8 lg:text-center">
          <div className="mb-1 text-[32px] leading-tight  lg:mb-0 lg:text-center lg:text-[64px] [&_em]:font-serif [&_em]:not-italic">
            <HTMLParser html={title} />
          </div>
          <p className="text-base">{text}</p>
        </div>
        <form className="w-full flex-col gap-2" onSubmit={handleSubmit}>
          <div className="flex w-full flex-col items-center ">
            <label
              className="block w-full border-b border-white"
            >
              <span className="sr-only">{t("form.email")}</span>
              <input
                className={cn(
                  "w-full bg-transparent py-5 font-serif text-2xl placeholder:text-[inherit] placeholder:opacity-80 lg:pb-6 lg:text-center lg:text-[32px]",
                  {
                    "text-stormcloud": sentRequest,
                  },
                )}
                type="email"
                id="email"
                name="email"
                value={!sentRequest ? email : message.content}
                onChange={e => setEmail(e.target.value)}
                autoComplete="email"
                placeholder={t("form.email")}
                required
              />
            </label>
            <button className="mx-auto mt-8 inline-block w-full rounded-full bg-white py-2 text-dark-green lg:max-w-[170px]">
              {t("action.newsletter_submit")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

Newsletter.propTypes = {
  disabledYPadding: PropTypes.bool,
  listId: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default Newsletter;
